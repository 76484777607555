<template>
  <article
    v-if="!loading && meeting"
    class="box"
  >
    <h1 class="page-title">
      {{ meeting.title }}
    </h1>
    <VCard>
      <VListItem
        v-if="period"
        dense
      >
        <VListItemIcon>
          <VIcon>access_time</VIcon>
        </VListItemIcon>
        <VListItemContent>
          <VListItemTitle>
            <time>{{ period }}</time>
          </VListItemTitle>
        </VListItemContent>
      </VListItem>

      <VCardText class="text--primary">
        <p>
          <label>{{ $t('statuses.one') }}: </label>
          <span :class="statusColors">{{ statusTitle }}</span>
        </p>
        <p>
          <label>{{ $t('managers.one') }}: </label>
          <span> {{ meeting.manager_title || $t('not_specified.one') }}</span>
        </p>
        <p>
          <label>{{ $t('speakers.one') }}: </label>
          <span> {{ meeting.speaker_title || $t('not_specified.one') }}</span>
        </p>
      </VCardText>

      <VCardText
        v-if="meeting.description"
        class="text--primary"
      >
        <label>{{ $t('descriptions.one') }}: </label>
        <div
          class="rte-content"
          v-html="meeting.description"
        />
      </VCardText>

      <VCardActions v-if="(meeting.status.code !== 'completed') && (meeting.status.code !== 'canceled')">
        <VBtn
          :href="uri"
          target="_blank"
          rel="noopener nofollow noreferrer"
          large
          color="primary"
        >
          {{ $t('$app.meeting.action') }}
        </VBtn>
      </VCardActions>
    </VCard>
  </article>

  <VProgressCircular
    v-else
    :size="50"
    color="primary"
    indeterminate
    class="app-loader"
  />
</template>

<script>
import documentTitle from '@/mixins/documentTitle'
import { prepareMeetingStatus } from '@/utils/status'
import { UDate } from '@/utils/date'
import { getMeeting } from '@/api/api'

export default {
  name: 'TheMeeting',
  mixins: [documentTitle],

  data () {
    return {
      loading: true,
      meeting: null
    }
  },

  computed: {
    statusColors () {
      return {
        'warning--text': this.meeting.status.code === 'accepted',
        'error--text': this.meeting.status.code === 'pending',
        'success--text': this.meeting.status.code === 'started',
        'secondary--text': this.meeting.status.code === 'canceled'
      }
    },

    statusTitle () {
      const status = prepareMeetingStatus(this.meeting?.status?.code)
      return this.$t(status.title)
    },

    period () {
      let str = ''
      if (new UDate(this.meeting?.starts_at).valueOf()) {
        str += UDate.formatDateTime(this.meeting?.starts_at)
      }

      if (new UDate(this.meeting?.ends_at).valueOf()) {
        str += `— ${UDate.formatDateTime(this.meeting?.ends_at)}`
      }

      return str
    },

    uri () {
      return `${location.origin}/meetings/${this.meeting.id}/redirect`
    }
  },

  watch: {
    $route: {
      handler: async function (to) {
        const meetingID = to.params.meetingID

        try {
          this.loading = true
          const { meeting } = await getMeeting(meetingID)
          this.meeting = meeting
          this.documentTitle = meeting.title
          this.loading = false
        } catch {
          //
        }
      },
      immediate: true
    }
  },
}
</script>
