export class Status {
  title: string
  icon: string
  classNames: string
  color: string

  constructor ({ title = '', icon = '', classNames = '', color = '' }) {
    this.title = title
    this.icon = icon
    this.classNames = classNames
    this.color = color
  }
}

const SESSION_STATUSES = new Map([
  [
    // Обучение ещё не начато.
    'pending',
    new Status({
      title: 'session_status_pending.one',
      classNames: '',
    })
  ],
  [
    // Обучение начато.
    'started',
    new Status({
      title: 'session_status_started.one',
      classNames: 'info--text',
    })
  ],
  [
    // Обучение пропущено.
    'skipped',
    new Status({
      title: 'session_status_skipped.one',
      classNames: 'warning--text',
    })
  ],
  [
    // Обучение завершено.
    'completed',
    new Status({
      title: 'session_status_completed.one',
      classNames: 'primary--text',
    })
  ],
  [
    // Обучение проверено, но результат не принят (отправлено на доработку).
    'evaluated',
    new Status({
      title: 'session_status_evaluated.one',
      classNames: 'error--text',
    })
  ],
  [
    // Обучение провалено.
    'rejected',
    new Status({
      title: 'session_status_rejected.one',
      classNames: 'error--text',
    })
  ],
  [
    // Обучение проверено и результат успешно принят.
    'accepted',
    new Status({
      title: 'session_status_accepted.one',
      classNames: 'success--text',
    })
  ],
])

const STEP_STATUSES = new Map([
  [
    'pending',
    new Status({
      title: 'step_status_pending.one',
      classNames: '',
    })
  ],
  [
    'started',
    new Status({
      title: 'step_status_started.one',
      classNames: 'info--text',
      icon: 'school'
    })
  ],
  [
    'skipped',
    new Status({
      title: 'step_status_skipped.one',
      classNames: 'warning--text',
      icon: 'cached'
    })
  ],
  [
    'completed',
    new Status({
      title: 'step_status_completed.one',
      classNames: 'primary--text',
      icon: '$uchi_schedule',
    })
  ],
  [
    'evaluated',
    new Status({
      title: 'step_status_evaluated.one',
      classNames: 'error--text',
      icon: 'priority_high',
    })
  ],
  [
    'rejected',
    new Status({
      title: 'step_status_rejected.one',
      classNames: 'error--text',
      icon: 'priority_high',
    })
  ],
  [
    'accepted',
    new Status({
      title: 'step_status_accepted.one',
      classNames: 'success--text',
      icon: 'done'
    })
  ],
  [
    'disabled',
    new Status({
      title: '',
      classNames: '',
      icon: 'lock',
    })
  ]
])

const SURVEY_STATUSES = new Map([
  [
    'pending',
    new Status({
      title: '$app.survey_pending',
      classNames: 'success--text',
    })
  ],
  [
    'started',
    new Status({
      title: '$app.survey_started',
      classNames: 'primary--text',
    })
  ],
  [
    'completed',
    new Status({
      title: '$app.survey_completed',
      classNames: 'error--text',
    })
  ],
])

const MEETING_STATUSES = new Map([
  [
    'pending',
    new Status({
      title: 'meeting_status_pending.one',
    })
  ],
  [
    'accepted',
    new Status({
      title: 'meeting_status_accepted.one',
    })
  ],
  [
    'started',
    new Status({
      title: 'meeting_status_started.one',
    })
  ],
  [
    'completed',
    new Status({
      title: 'meeting_status_completed.one',
    })
  ],
  [
    'canceled',
    new Status({
      title: 'meeting_status_canceled.one',
    })
  ]
])

const LESSON_STATUSES = new Map([
  [
    'accepted',
    new Status({
      title: '$app.lesson_status_accepted',
      color: 'success',
    })
  ],
  [
    'rejected',
    new Status({
      title: '$app.lesson_status_rejected',
      color: 'error',
    })
  ],
  [
    'started',
    new Status({
      title: '$app.lesson_status_started',
      color: 'primary'
    })
  ],
  [
    'completed',
    new Status({
      title: '$app.lesson_status_completed',
      color: 'warning'
    })
  ],
])

export const prepareStatus = (statuses: Map<string, Status>) => {
  if (!statuses.size) {
    return
  }

  return (code: string) => statuses.get(code) || {}
}

export const prepareSessionStatus = prepareStatus(SESSION_STATUSES)
export const prepareStepStatus = prepareStatus(STEP_STATUSES)
export const prepareSurveyStatus = prepareStatus(SURVEY_STATUSES)
export const prepareMeetingStatus = prepareStatus(MEETING_STATUSES)
export const prepareLessonStatus = prepareStatus(LESSON_STATUSES)
